import axios from 'axios'
import React, { useEffect, useState, useRef } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  MuiThemeProvider,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ReactTable from 'react-table'
import NumberInputMobile from './NumberInputMobile'
import '../types'
import SortableBasicTable from './SortableBasicTable'
import { useDebounce } from '../customHooks'
import { blue } from '@material-ui/core/colors';
import { darken } from '@material-ui/core/styles/colorManipulator';

/**
 * A component which finds the closest DOM element matching the `selector` and
 * adds an `eventName` EventListener to the document which calls the
 * `spyRefCallback` everytime the event is triggered. `spyRefCallback` function
 * takes 2 positional arguments `event` & `element`. `event` passes the event from
 * the `eventName` event listener. `element` passes the DOM element matching
 * the `selector`.
 * @param {object} params
 * @param {string} params.selector - css selector of DOM element to capture
 * @param {string} params.eventName - the name of the event listener to add to document
 * @param {Function} params.spyRefCallback - function to call when `eventName` triggers
 * @param {Array} params.args - additional args to pass to `spyRefCallback`
 */
const ComponentSpy = ({ selector, eventName, spyRefCallback, args }) => {

  const spyRef = useRef()
  const eventListener = (event) => {
    // Get the closest parent table row
    const element = spyRef.current.closest(selector)
    spyRefCallback(event, element, ...args)
  }
  useEffect(() => {

    document.addEventListener(eventName, eventListener, true)

    return () => {
      document.removeEventListener(eventName, eventListener, true)
    }
  })
  return (<span ref={spyRef}></span>)
}
const ScrollToSkuRef = ({ scannedSku, rowSku, scanLoc, rowLoc }) => {
  const ref = useRef()

  useEffect(() => {
    if (scannedSku == rowSku && scanLoc == rowLoc) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [scannedSku])

  return (<span ref={ref}></span>)
}

/**
 * Making this cell it's own component so I can use hooks to make sure each
 * Input has it's own timeout ID so that each button is debounced individually
 * rather than all the buttons sharing a timeoutId making them debounce together
 */
const DebouncedCountCell = ({
  row,
  setActiveCountProductField,
  updateTotalCountForLocation,
  postUpdatedCount
}) => {
  const debouncedUpdateCounted = useDebounce(postUpdatedCount, 500)

  return (
    <NumberInputMobile
      value={row.original.counted}
      key={`counted:${row.original._id}`}
      onChange={(event) => {
        if (event.target.value < 0)
          return
        setActiveCountProductField({
          locationId: Number(row.original.locationId),
          bpId: Number(row.original.bpId),
          field: 'counted',
          value: Number(event.target.value)
        })
        updateTotalCountForLocation(row.original.locationId)
        debouncedUpdateCounted({
          countSetId: Number(row.original.countSetId),
          locationId: Number(row.original.locationId),
          bpId: Number(row.original.bpId),
          value: Number(event.target.value)
        })
      }}

      defaultValue={row.value}
      disabled={row.original.disabled}
      onKeyDown={(event) => event.stopPropagation()}
    />
  )
}


/**
 * Page to audit the number of products in a location in the main warehouse
 * @param {object} params
 * @param {Destination[]} params.locations
 * @param {number} params.activeCountSetId
 * @param {Function} params.setActiveCountSetId
 */
const LocationCount = ({
  locations, activeCountSetId, setActiveCountSetId, expanded, setExpanded
}) => {
  /**
   * @typedef ProductRowResolve
   * @property {number} locationId
   * @property {number} countSetId
   *
  * @typedef ProductRowStatus
  * @property {boolean} disabled
  *
  * @typedef LocationRowStatus
  * @property {boolean} disabled
  *
  * @typedef CountSet
  * @property {number} countSetId
  * @property {string[]} locations
  */

  const ScanStatus = Object.freeze({
    OFF: 'OFF',
    SINGLE_COUNT: 'SINGLE_COUNT',
    MULTI_COUNT: 'MULTI_COUNT',
    ADD_MISSING: 'ADD_MISSING'
  })

  /** @type {[LocationCount[], React.Dispatch<LocationCount[]>]} */
  const [activeCounts, setActiveCounts] = useState([])
  /** @type {[CountSet[], React.Dispatch<CountSet[]>]} */
  const [countSets, setCountSets] = useState([])
  const [scanningSkus, setScanningSkus] = useState([])
  const [lastScannedSku, setLastScannedSku] = useState()
  const [scanningLocationId, setScanningLocationId] = useState('')
  const [scanStatus, setScanningStatus] = useState(ScanStatus.OFF)
  const [openModal, setOpenModal] = useState(false)
  const [modalContent, setModalContent] = useState({ title: '', message: '' })
  const rowRefs = useRef([])
  const [stickyRow, setStickyRow] = useState()
  const audioRef = useRef()

  // Sticky Row Constants-------------------------------------------------------
  const topBarHeight = 44
  const headerHeight = 29
  // ---------------------------------------------------------------------------
  const scanErrorSoundFile = '/clapSound.wav'

  /**
   * Utility function to set all the state generally associated w/ scan status
   * @param {object} params
   * @param {string} params.sku
   * @param {ScanStatus} params.status
   * @param {number} params.locationId
   */
  const setScanningStates = ({ status, locationId, sku }) => {

    if (status == ScanStatus.OFF) {
      setScanningSkus([])
      setScanningLocationId('')
      setScanningStatus(status)
    }
    else if (status == ScanStatus.SINGLE_COUNT) {
      setScanningLocationId(locationId)
      setScanningSkus([sku])
      setScanningStatus(status)
    }
    else if (status == ScanStatus.MULTI_COUNT) {
      const loc = activeCounts.find((loc) => loc.locationId == locationId)
      setScanningSkus(loc.products.map((prod) => prod.sku))
      setScanningLocationId(locationId)
      setScanningStatus(status)
    }
    else if (status == ScanStatus.ADD_MISSING) {
      setScanningSkus([])
      setScanningLocationId(locationId)
      setScanningStatus(status)
    }
  }

  const getActiveCounts = () => {
    const params = { status: 'active' }
    axios.get('/api/get-location-counts', { params }).then((res) => {
      let countSetIds = {}
      /** @type {LocationCount[]}*/
      let activeCountsClone = [...res.data]
      // Add a disabled state to each product to enable/disable editing
      // location count data for this product
      activeCountsClone.forEach((loc) => {
        loc['disabled'] = true
        loc.products.forEach((product) => {
          product['disabled'] = true
        })
        if (countSetIds[loc.countSetId])
          countSetIds[loc.countSetId].push(loc.location)
        else
          countSetIds[loc.countSetId] = [loc.location]
      })
      let countSetState = Object.keys(countSetIds).map((key) => {
        return {
          countSetId: key,
          locations: countSetIds[key].sort()
        }
      })
      setCountSets([...countSetState])
      setActiveCounts(activeCountsClone)
    })
  }

  const handleSkuScan = (event) => {

    const scannedSku = event.detail

    if (scanStatus == ScanStatus.ADD_MISSING) {
      addMissingProductToCount(scannedSku)
      setScanningStates({ status: ScanStatus.OFF })
      return
    } else if (
      scanStatus == ScanStatus.SINGLE_COUNT ||
      scanStatus == ScanStatus.MULTI_COUNT
    ) {
      incrementCountWithScanner(scannedSku)
    }
  }

  const handleNotSkuScan = (event) => {
    const scannedBarcode = event.detail
    const pausedScanStatus = scanStatus
    setScanningStatus(ScanStatus.OFF)
    setModalContent({
      title: 'Scanned barcode is not a SKU',
      message: `Couldn't process because ${scannedBarcode} is not a SKU`,
      actions: (
        <Button
          variant='contained'
          fullWidth={true}
          onClick={() => {
            setScanningStatus(pausedScanStatus)
            setOpenModal(false)
          }}
        >
          ok
        </Button>
      )
    })
    audioRef.current.play()
    setOpenModal(true)
  }

  useEffect(() => {
    document.addEventListener('onSkuScan', handleSkuScan)
    document.addEventListener('notSkuScan', handleNotSkuScan)
    audioRef.current = new Audio(process.env.PUBLIC_URL+scanErrorSoundFile)
    if (activeCounts.length == 0) {
      getActiveCounts()
    }
    return () => {
      document.removeEventListener('onSkuScan', handleSkuScan)
      document.removeEventListener('notSkuScan', handleNotSkuScan)
    }
  }, [scanningSkus, scanStatus])


  const getActiveCountProduct = ({
    activeCountsCopy, locationId, bpId
  }) => {
    let activeLocation = activeCountsCopy.find(
      (loc) => loc.locationId == locationId
    )
    /** @type {ProductAudit & ProductRowStatus} */
    let activeProduct = activeLocation.products.find(
      (product) => product.bpId == bpId
    )
    return activeProduct
  }

  const setActiveCountProductField = ({
    locationId, bpId, field, value
  }) => {
    let activeCountsClone = [...activeCounts]
    let product = getActiveCountProduct({
      activeCountsCopy: activeCountsClone,
      locationId: locationId,
      bpId: bpId
    })
    product[field] = value
    setActiveCounts(activeCountsClone)
  }

  const updateTotalCountForLocation = (locationId) => {
    let activeCountsClone = [...activeCounts]
    let activeLocation = activeCountsClone.find((loc) => {
      return loc.locationId == locationId
    })
    activeLocation.totalCounted = 0
    activeLocation.products.forEach(
      (product) => {
        activeLocation.totalCounted += Number(product.counted)
      })
    setActiveCounts(activeCountsClone)
  }
  /**
   * Count product with `sku` using scanner
   * @param {string} sku - the sku scanned in by the scanner
   */
  const incrementCountWithScanner = (sku) => {

    if (scanningSkus.length == 0)
      return

    let activeCountsClone = [...activeCounts]
    /** @type {LocationCount} */
    let activeLocation = activeCountsClone.find(
      (loc) => loc.locationId == scanningLocationId
    )
    /** @type {ProductAudit} */
    let activeProduct = activeLocation.products.find(
      (product) => product.sku == sku
    )
    if (!activeProduct) {
      audioRef.current.play()
      const scanningLocation = locations.find(
        (loc) => loc.id == scanningLocationId
      )
      const thisLocation = (scanningLocation ? scanningLocation.location : '')
      const pausedScanStatus = scanStatus
      setScanningStatus(ScanStatus.OFF)
      setModalContent({
        title: 'Scanning Error: Can\'t count product. Add?',
        message: (
          <>
            Scanned SKU
            <Typography variant='h6' display='inline'>
              {` ${sku} `}
            </Typography>
            is not present in this location
            <Typography variant='h6' display='inline'>
              {` ${thisLocation} `}
            </Typography>
            would you like to add it?
          </>
        ),
        actions: (
          <>
            <Button
              color='secondary'
              variant='contained'
              fullWidth={true}
              onClick={() => {
                setScanningStatus(pausedScanStatus)
                setOpenModal(false)
              }}
            >no</Button>
            <Button
              color='primary'
              variant='contained'
              fullWidth={true}
              onClick={() => {
                setScanningStatus(pausedScanStatus)
                addMissingProductToCount(sku)
                setScanningSkus([...scanningSkus, sku])
                setOpenModal(false)
              }}
            >yes</Button>
          </>
        )
      })
      setOpenModal(true)
      return
    }
    if (!scanningSkus.includes(sku)) {
      return
    }
    activeLocation.totalCounted += 1
    activeProduct.counted += 1
    postUpdatedCount({
      countSetId: activeLocation.countSetId,
      locationId: activeLocation.locationId,
      bpId: activeProduct.bpId,
      value: activeProduct.counted
    })
    setActiveCounts(activeCountsClone)
    setLastScannedSku(activeProduct.sku)
  }

  const addMissingProductToCount = (sku) => {

    const params = {
      sku: sku
    }
    axios.get('/api/get-product-by-sku', { params }).then((res) => {
      let product = res.data != undefined ? res.data[0] : undefined
      if (product == undefined)
        return
      let activeCountsClone = [...activeCounts]
      let activeLocation = activeCountsClone.find((loc) => {
        return loc.locationId == scanningLocationId
      })
      if (!activeLocation)
        return
      const existingSkus = activeLocation.products.map((product) => product.sku)
      const skuAlreadyExists = existingSkus.includes(sku)
      if (skuAlreadyExists) {
        audioRef.current.play()
        setOpenModal(true)
        setScanningStates({ status: ScanStatus.OFF })
        setModalContent({
          title: 'Scanning Error: Product already here ',
          message: (
            <span>
              Scanned SKU
              <Typography variant='h6' display='inline'>
                {` ${sku} `}
              </Typography>
              is already present in this location
              <Typography variant='h6' display='inline'>
                {activeLocation.location}
              </Typography>
            </span>
          )
        })
        return
      }
      const bpName = product.bpName.split(';')
      activeLocation.products.push(
        {
          bpId: product.bpId,
          sku: product.sku,
          name: bpName[1],
          brand: bpName[0],
          onHand: 0,
          counted: 1,
          note: '',
          disabled: true
        }
      )
      axios.post('/api/add-product-to-location-count', {
        countSetId: activeLocation.countSetId,
        locationId: scanningLocationId,
        bpId: product.bpId,
      }).then((_res) => {
        setActiveCounts(activeCountsClone)
        setLastScannedSku(sku)
      })
    })
  }

  const postUpdatedCount = ({
    countSetId, locationId, bpId, value
  }) => {
    return axios.post('/api/update-location-count', {
      countSetId: countSetId,
      locationId: locationId,
      bpId: bpId,
      value: value
    })
  }


  const debouncedSetStickyRow = useDebounce(setStickyRow, 20)

  const calculateStickyHeader = (_event, element, locationId, isExpanded) => {

    let rect = element.getBoundingClientRect()
    let childRect = element.firstChild.getBoundingClientRect()

    rowRefs.current.push({
      locId: locationId,
      isExpanded: isExpanded,
      top: rect.top,
      width: rect.width,
      height: childRect.height,
      parentHeight: rect.height,
      bottom: rect.bottom
    })
    if (rowRefs.current.length == activeCountSet.length) {

      const stickyRowHeight = stickyRow ? stickyRow.height : 0
      const anchorTopHeight = topBarHeight + stickyRowHeight
      const hiddenRows = rowRefs.current.filter((locId) => (
        locId.top < anchorTopHeight &&
        locId.bottom > anchorTopHeight + (2 * headerHeight) &&
        locId.isExpanded
      ))
      const sortedHiddenRows = hiddenRows.sort((a, b) => b.top - a.top)
      const newStickyRow = sortedHiddenRows[0]
      if ((stickyRow && !newStickyRow) || (!stickyRow && newStickyRow) ||
        (stickyRow && newStickyRow) &&
        (stickyRow.locId != newStickyRow.locId)) {
        debouncedSetStickyRow(newStickyRow)
      }
      rowRefs.current = []
    }
  }

  const activeColumns = [
    {
      Header: 'Count Set ID',
      accessor: 'countSetId',
      Cell: (row) => {
        return (
          <div
            style={{ textAlign: "center" }}
          >
            <ComponentSpy
              selector='[class^="rt-tr-group"]'
              eventName='scroll'
              spyRefCallback={calculateStickyHeader}
              args={[row.original.locationId, row.isExpanded]}
            />
            {row.value}
          </div>)
      },
      width: 120
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 60
    },
    {
      Header: 'Location',
      accessor: 'location',
      width: 85
    },
    {
      expander: true,
      Header: 'Products',
      id: 'products',
      accessor: '_original',
      sortable: true,
      width: 100,
      Expander: ({ isExpanded, row }) => {
        return (
          <div
            style={{
              display: 'inline-flex', padding:'11px 5px' , width:'100%', height:'100%'
            }}
            onClick={() => {
              if (isExpanded) {
                let activeCountsClone = [...activeCounts]
                /** @type {LocationCount & LocationRowStatus} */
                let activeLocation = activeCountsClone.find((loc) => {
                  return loc.locationId == row.locationId
                })
                // Lock all product rows on collapse
                activeLocation.disabled = true
                activeLocation.products.map((product) => {
                  product.disabled = true
                })
                if (scanningLocationId == row.locationId) {
                  setScanningStates({ status: ScanStatus.OFF })
                }
                setActiveCounts(activeCountsClone)
              }
            }}
          >
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            ({row._original.totalCounted}/{row._original.totalOnHand})
          </div>
        )
      }
    },
    {
      Header: 'Date Created',
      accessor: 'createdAt',
      Cell: (row) => {
        /** @type {Date} */
        const dateCreated = new Date(row.original.createdAt)
        return dateCreated.toDateString()
      },
      width: 140
    },
    {
      Header: 'Scan Multiple',
      accessor: 'locationId',
      sortable: false,
      width: 120,
      Cell: (row) => {
        const isScanningHere = (scanningLocationId == row.value) &&
          (scanStatus == ScanStatus.MULTI_COUNT)
        return (
          <MuiThemeProvider theme={buttonTheme}>
            <div
              key={`multi:${row.original._id}`}
              style={{ textAlign: 'center' }}>
              <Button
                variant='contained'
                color={isScanningHere ? 'secondary' : 'primary'}
                onClick={(event) => {
                  event.currentTarget.blur()
                  if (!isScanningHere) {
                    setScanningStates({
                      status: ScanStatus.MULTI_COUNT,
                      locationId: row.original.locationId
                    })
                  } else {
                    setScanningStates({ status: ScanStatus.OFF })
                  }
                }}
                disabled={!row.isExpanded}
              >{(isScanningHere) ? 'stop scan' : 'multiscan'}
              </Button>
            </div>
          </MuiThemeProvider>
        )
      }
    },
    {
      Header: 'Bulk Unlock',
      width: 100,
      sortable: false,
      Cell: (row) => {
        const isDisabled = row.original.disabled
        const lockIcon = (isDisabled ? <LockIcon /> : <LockOpenIcon />)
        return (
          <div style={{ textAlign: 'center' }}>
            <IconButton
              disabled={!row.isExpanded}
              onClick={(event) => {
                event.currentTarget.blur()
                toggleBulkProductRowLock(row.original.locationId)
              }}
            >
              {lockIcon}
            </IconButton>
          </div>
        )
      }
    }
  ]
  const productColumns = (locationId) => ([
    {
      Header: 'SKU',
      accessor: 'sku',
      width: 100,
      Cell: (row) => (
        <span>
          <ScrollToSkuRef
            scannedSku={lastScannedSku} rowSku={row.value}
            scanLoc={scanningLocationId} rowLoc={row.original.locationId}/>
          <Typography align='center' style={{ paddingTop: '20px' }}>
            {row.value}
          </Typography>
        </span>
      )
    },
    {
      Header: 'Brand',
      accessor: 'brand',
      width: 160,
      Cell: (row) => (
        <Typography align='center' style={{ paddingTop: '20px' }}>
          {row.value}
        </Typography>
      ),
    },

    {
      Header: 'Name',
      accessor: 'name',
      width: 240,
      Cell: (row) => (
        <Typography style={{ paddingTop: '20px' }}>
          {row.value}
        </Typography>
      ),
    },
    {
      Header: 'On Hand',
      accessor: 'onHand',
      width: 80,
      Cell: (row) => (
        <Typography align='center' style={{ paddingTop: '20px' }}>
          {row.value}
        </Typography>
      )
    },
    {
      Header: 'Counted',
      accessor: 'counted',
      width: 160,
      Cell: (row) => (
        <DebouncedCountCell
          row={row}
          setActiveCountProductField={setActiveCountProductField}
          updateTotalCountForLocation={updateTotalCountForLocation}
          postUpdatedCount={postUpdatedCount}
        />
      )
    },
    {
      Header: 'Unlock',
      accessor: 'disabled',
      width: 60,
      sortable: false,
      Cell: (row) => {
        const lockIcon = (row.value ? <LockIcon /> : <LockOpenIcon />)
        return (
          <IconButton
            key={`lock:${row.original._id}`}
            onClick={(event) => {
              event.currentTarget.blur()
              if (row.value == false &&
                scanStatus == ScanStatus.SINGLE_COUNT &&
                scanningSkus[0] == row.original.sku
              ) {
                setScanningStates({ status: ScanStatus.OFF })
              }
              toggleProductRowLock(row)
            }}>
            {lockIcon}
          </IconButton>
        )
      },
      Filter: ({}) => {
        let locRow = activeCounts.find((loc) => loc.locationId == locationId)
        const lockIcon = locRow.disabled ? <LockIcon /> : <LockOpenIcon />
        return (
          <Button
            key={`bulkLock:${locationId}`}
            size='small'
            style={{ minWidth: 45 }}
            onClick={() => {
              toggleBulkProductRowLock(locationId)
            }}
          >
            {lockIcon}
          </Button>
        )
      },
    },
    // {
    //   Header: 'Note',
    //   accessor: 'note',
    //   width: 160,
    //   Cell: (row) => {
    //     return (
    //       <TextareaAutosize
    //         value={row.original.note}
    //         key={`note:${row.original.id}`}
    //         placeholder='note'
    //         onChange={(event) => {
    //           setActiveCountProductField({
    //             locationId: row.original.locationId,
    //             bpId: row.original.bpId,
    //             field: 'note',
    //             value: event.target.value
    //           })
    //           debouncedUpdateNote({
    //             countSetId: row.original.countSetId,
    //             locationId: row.original.locationId,
    //             bpId: row.original.bpId,
    //             value: event.target.value
    //           })
    //         }}
    //         style={{
    //           fontSize: 13,
    //           /* width: 280, */
    //           /* maxWidth: 380, */
    //           height: 54,
    //           fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    //           fontWeight: 400,
    //         }}
    //         disabled={row.original.disabled}
    //         onKeyDown={(event) => event.stopPropagation()}
    //       />
    //     )
    //   }
    // },
    {
      Header: 'Scan',
      accessor: 'disabled',
      sortable: false,
      filterable: false,
      width: 100,
      Cell: (row) => {
        const isScanningHere = (scanningSkus[0] == row.original.sku) &&
          (scanStatus == ScanStatus.SINGLE_COUNT)
        return (
          <ThemeProvider theme={buttonTheme}>
            <div
              style={{ paddingTop: 10, textAlign: 'center' }}
              key={`scan:${row.original._id}`}>
              <Button
                variant='contained'
                color={isScanningHere ? 'secondary' : 'primary'}
                disabled={row.value}
                onClick={(event) => {
                  event.currentTarget.blur()
                  if (!isScanningHere) {
                    setScanningStates({
                      status: ScanStatus.SINGLE_COUNT,
                      locationId: row.original.locationId,
                      sku: row.original.sku
                    })
                  } else {
                    setScanningStates({ status: ScanStatus.OFF })
                  }
                }}>
                {isScanningHere ? 'stop' : 'scan'}
              </Button>
            </div>
          </ThemeProvider>
        )
      }
    },
    {
      Header: 'Delete',
      width: 60,
      filterable: false,
      Cell: (row) => {
        return (
          <>
            {(row.original.onHand == 0) && (
              <IconButton
                disabled={row.original.disabled}
                onClick={() => {

                  const newScanningSkus = scanningSkus.filter(
                    sku => sku != row.original.sku
                  )
                  const activeCountsClone = [...activeCounts]
                  let activeLocation = activeCountsClone.find((loc) => {
                    return loc.locationId == row.original.locationId
                  })
                  const deleteIndex = activeLocation.products.findIndex(
                    (product) => product.bpId == row.original.bpId)
                  axios.post('/api/delete-product-from-location-count', {
                    countSetId: row.original.countSetId,
                    locationId: row.original.locationId,
                    bpId: row.original.bpId
                  }).then((_res) => {
                    activeLocation.products.splice(deleteIndex, 1)
                    setScanningSkus(newScanningSkus)
                    setActiveCounts(activeCountsClone)
                  })
                }}
              ><DeleteForeverIcon />
              </IconButton>
            )}
          </>
        )
      }
    }
  ])

  /**
   * Toggles disabled state for this row
   * @param {ProductAudit & ProductRowResolve} row
   */
  const toggleProductRowLock = (row) => {

    let activeCountsClone = [...activeCounts]
    let product = getActiveCountProduct({
      activeCountsCopy: activeCountsClone,
      locationId: row.original.locationId,
      bpId: row.original.bpId
    })
    product.disabled = !product.disabled
    setActiveCounts(activeCountsClone)
  }

  const toggleBulkProductRowLock = (locationId) => {
    let activeCountsClone = [...activeCounts]
    /** @type {LocationCount & LocationRowStatus}*/
    let row = activeCountsClone.find((loc) => loc.locationId == locationId)
    if (scanStatus == ScanStatus.SINGLE_COUNT &&
        scanningLocationId == locationId) {
      setScanningStates({
        status: ScanStatus.OFF
      })
    }
    row.disabled = !row.disabled
    row.products.forEach((product) => {
      product.disabled = row.disabled
    })
    setActiveCounts(activeCountsClone)
  }

  const stickyRowBackground = (width, height) => {
    return (
      <>
        <span
          className='stickyRowBackground'
          style={{
            position: 'fixed', top: topBarHeight, zIndex: 1,
            width: width, height: height + (2 * headerHeight), background: 'white',
          }}
        >
        </span>
      </>
    )
  }
  const dialogTheme = createTheme({
    overrides: {
      MuiDialog: {
        paperWidthSm: {
          maxHeight: 600
        }
      }
    }
  })

  const buttonTheme = createTheme({
    nprogress: {
      color: '#000'
    },
    palette: {
      primary: {
        main: darken(blue.A400, 0.0)
      }
      , type: 'light'
    },
  })

  const notificationModal = (
    <ThemeProvider theme={dialogTheme}>
      <Dialog
        /* style={{ maxHeight: 600 }} */
        open={openModal}>
        <DialogTitle>
          {modalContent.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={'span'}>
            {modalContent.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {modalContent.actions || (
            <Button
              onClick={() => {
                setOpenModal(false)
              }}
            >Close</Button>)}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
  // If onHand matches counted OR if product has note mark the product counted
  // If all products are counted then enable 'Done' button
  /** @type {boolean[]} */
  let productDiscrepancies = []
  let activeCountSet = activeCounts.filter((loc) => loc.countSetId == activeCountSetId)
  activeCountSet.forEach((loc) => {
    loc.products.forEach((product) => {
      if (product.counted != product.onHand) {
        productDiscrepancies.push({ ...product, location: loc.location })
      }
    })
  })
  productDiscrepancies.sort((a, b) => {
    const aDifference = Math.abs(a.counted - a.onHand)
    const bDifference = Math.abs(b.counted - b.onHand)
    return bDifference - aDifference
  })
  /** @type {boolean} */
  // const allProductsCounted = productsCounted.reduce(
  //   (prevCounted, product) => prevCounted && product, productsCounted[0]
  // )
  // const readyForReview = allProductsCounted || false

  return (
    <>
      {
        stickyRow &&
        stickyRowBackground(stickyRow.width, stickyRow.height)
      }
      {notificationModal}
      <Typography
        style={{ marginInline: 15, marginTop: 5 }}
        variant='subtitle1'>
        Select an active count set to verify the number of products in that location range
      </Typography>
      <Grid container spacing={2} alignItems='center' style={{ padding: 15 }}>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            value={
              countSets.find(
                (countSet) => countSet.countSetId == activeCountSetId
              ) || ''
            }
            options={countSets}
            onChange={(_event, newValue, reason) => {
              setExpanded({})
              if (reason == 'clear') {
                setActiveCountSetId()
              }
              else {
                setActiveCountSetId(newValue.countSetId)
              }
            }}
            getOptionLabel={(option) => {
              if (!option)
                return ''
              const first = option.locations[0]
              const last = option.locations[option.locations.length - 1]
              return `${option.countSetId}: ${first} -> ${last}`
            }}
            getOptionSelected={
              (option, value) => option.countSetId === value.countSetId
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label='Count Sets' variant='outlined'
              />
            )}
          />
        </Grid>
        <Grid item >
          <Button
            disabled={!activeCountSetId}
            fullWidth={true}
            size='large'
            variant='contained'
            onClick={() => {
              const title = 'Are you sure you are done counting this set?'
              const message = (
                <span>
                  The following skus have discrepencies:
                  <SortableBasicTable
                    columns={[{
                      Header: 'Location',
                      accessor: 'location'
                    }, {
                      Header: 'sku',
                      accessor: 'sku'
                    }, {
                      Header: 'counted',
                      accessor: 'counted'
                    }, {
                      Header: 'on hand',
                      accessor: 'onHand'
                    }]}
                    data={productDiscrepancies}
                  />
                </span>
              )
              const actions = (
                <>
                  <Button
                    variant='contained'
                    color='secondary'
                    fullWidth={true}
                    onClick={() => {
                      setOpenModal(false)
                    }}>no</Button>
                  <Button
                    variant='contained'
                    color='primary'
                    fullWidth={true}
                    onClick={() => {
                      axios.post('/api/update-count-set-status', {
                        countSetId: activeCountSetId,
                        value: 'review'
                      }).then(() => {
                        setOpenModal(false)
                        getActiveCounts()
                      })
                    }}
                  >yes</Button>
                </>
              )
              setModalContent({
                title: title,
                message: message,
                actions: actions
              })
              setOpenModal(true)
            }}
          >
            Done
          </Button>
        </Grid>
      </Grid>
      {activeCountSetId &&
        <ReactTable
          className='-striped'
          data={
            [...activeCounts.filter((loc) => loc.countSetId == activeCountSetId)]
          }
          columns={activeColumns}
          pageSize={activeCounts.length}
          showPageSizeOptions={false}
          showPagination={false}
          minRows={5}
          expanded={expanded}
          defaultSorted={[
            { id: 'countSetId', desc: false },
            { id: 'location', desc: false },
          ]}
          onExpandedChange={(newExpanded) => {
            setExpanded(newExpanded)
          }}
          onSortedChange={ (_newSorted, _column, _shiftKey) => {
            // Trigger a fake scroll event to update the sticky header when
            // the use sorts by a column
            const fakeScrollEvent = new CustomEvent('scroll')
            document.dispatchEvent(fakeScrollEvent)
          }}
          getTdProps={(_state, _rowInfo, column) => {
            if (column.id == 'products') {
              return {
                style: {
                  padding: 0
                }
              }
            }
            return {}
          }}
          getTrProps={(_state, rowInfo) => {
            if (!rowInfo || !stickyRow)
              return {}
            const isSticky = rowInfo.original.locationId == stickyRow.locId
            if (isSticky) {
              return {
                style: {
                  position: 'fixed',
                  zIndex: 2,
                  top: topBarHeight + headerHeight,
                  height: stickyRow.height,
                  width: stickyRow.width,
                }
              }
            }
            return {}
          }}
          getTheadProps={() => {
            if (stickyRow) {
              return {
                style: {
                  height: headerHeight + stickyRow.height
                }
              }
            } else {
              return {}
            }
          }}
          getTheadTrProps={() => {
            if (stickyRow) {
              return {
                style: {
                  boxShadow: '0 2px 15px 0 rgba(0,0,0,0.15)',
                  position: 'fixed',
                  top: topBarHeight,
                  zIndex: 2,
                  width: stickyRow.width,
                  background: 'white'
                }
              }
            } else
              return {}
          }}
          SubComponent={(row) => {
            return (
              <div>
                <ReactTable
                  key={`${row.original._id}`}
                  columns={productColumns(row.original.locationId)}
                  data={[...row.original.products]}
                  resolveData={(products) => {
                    let resolvedProducts = products.map(product => {
                      return {
                        ...product,
                        countSetId: row.original.countSetId,
                        locationId: row.original.locationId
                      }
                    })
                    return resolvedProducts
                  }}
                  getTrProps={(_state, rowInfo, _column) => {
                    if (!rowInfo)
                      return {}
                    let style = {}
                    const counted = rowInfo.original.counted
                    const onHand = rowInfo.original.onHand
                    /* const alpha = ((rowInfo.viewIndex+1) % 2) == 0 ? '0.05' : '0.1' */
                    if (counted < onHand && counted != 0) {
                      style['background'] = `rgb(255, 0, 0, 0.1)`
                    } else if (counted > onHand) {
                      style['background'] = `rgb(255, 60, 0, 0.3)`
                    } else if (counted == onHand) {
                      style['background'] = `rgb(0, 255, 0, 0.1)`
                    }
                    if (rowInfo.original.sku == lastScannedSku &&
                        rowInfo.original.locationId == scanningLocationId) {
                      style['outlineStyle'] = 'groove'
                      style['outlineOffset'] = -3
                    }
                    return {
                      style: {
                        ...style
                      }
                    }
                  }}
                  getTheadProps={() => {
                    if (stickyRow &&
                      (stickyRow.locId == row.original.locationId)) {
                      return {
                        style: {
                          height: headerHeight
                        }
                      }
                    } else {
                      return {}
                    }
                  }}
                  getTheadTrProps={() => {
                    if (stickyRow &&
                      (stickyRow.locId == row.original.locationId)) {
                      return {
                        style: {
                          position: 'fixed',
                          top: topBarHeight + stickyRow.height + headerHeight,
                          width: stickyRow.width,
                          zIndex: 2,
                          background: 'white',
                        }
                      }
                    } else {
                      return {}
                    }
                  }}
                  /* getTheadFilterProps={() => { */
                  /*   if (stickyRow && */
                  /*     (stickyRow.locId == row.original.locationId)) { */
                  /*     return { */
                  /*       style: { */
                  /*         position: 'fixed', */
                  /*         top: topBarHeight + stickyRow.height + (2 * headerHeight), */
                  /*         width: stickyRow.width, */
                  /*         zIndex: 2, */
                  /*         background: 'white', */
                  /*       } */
                  /*     } */
                  /*   } else { */
                  /*     return {} */
                  /*   } */
                  /* }} */
                  className='-striped'
                  filterable
                  pageSize={row.original.products.length + 1}
                  showPageSizeOptions={false}
                  minRows={5}
                  showPagination={false}
                  defaultSorted={[
                    { id: 'brand', desc: false },
                    { id: 'onHand', desc: true },
                    { id: 'sku', desc: false },
                  ]}
                />
                <Button
                  variant='contained'
                  color='primary'
                  onClick={(event) => {
                    event.currentTarget.blur()
                    if (scanStatus != ScanStatus.ADD_MISSING) {
                      setScanningStates({
                        status: ScanStatus.ADD_MISSING,
                        locationId: row.original.locationId
                      })
                    } else {
                      setScanningStates({ status: ScanStatus.OFF })
                    }
                  }}
                  style={{
                    padding: "10px",
                    marginBottom: '20px',
                    cursor: "pointer",
                    width: "100%"
                  }}
                >
                  {
                    (scanStatus == ScanStatus.ADD_MISSING) ?
                      'Waiting for Scan ... (Click to Cancel)' :
                      <><AddIcon />Add Product</>
                  }
                </Button>
              </div>
            )
          }}
        />}
    </>
  )
}

export default LocationCount
