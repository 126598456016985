import axios from 'axios'
import React, { useState, useEffect } from 'react'
import ReactTable from 'react-table'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextareaAutosize,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import LocationAutocomplete from './LocationAutocomplete'
import SortableBasicTable from './SortableBasicTable'

const buttonStyle = makeStyles((_theme) => (
  {
    voidButton: {
      backgroundColor: 'red',
      '&:hover': {
        backgroundColor: 'indianred'
      }
    }
  }
))

const CreateReviewLocationCount = ({ expanded, setExpanded }) => {

  /** @type {[ProductOnHandAtLocation[],
     React.Dispatch<ProductOnHandAtLocation[]>]} */
  const [onHand, setOnHand] = useState([])
  /** @type {[Destination[], React.Dispatch<Destination[]>]} */
  const [locations, setLocations] = useState([])
  /** @type {[Destination, React.Dispatch<Destination>]} */
  const [locationFrom, setLocationFrom] = useState('')
  /** @type {[Destination, React.Dispatch<Destination>]} */
  const [locationTo, setLocationTo] = useState('')
  /** @type {[LocationCount[], React.Dispatch<LocationCount[]>]} */
  const [reviewCounts, setReviewCounts] = useState([])
  const [mismatchProducts, setMismatchProducts] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [modalContent, setModalContent] = useState({ title: '', message: '' })

  const classes = buttonStyle()

  useEffect(() => {
    document.addEventListener('onLocationScan', handleLocationScan)

    if (locations.length == 0 && reviewCounts.length == 0) {
      const locationPromise = axios.get('/api/get-all-locations')
      const params = { status: 'review' }
      const countsPromise = axios.get('/api/get-location-counts', { params })
      const mismatchPromise = axios.get('/api/get-mismatch-counts')
      Promise.all(
        [locationPromise, countsPromise, mismatchPromise]
      ).then((res) => {
        setMismatchProducts(res[2].data)
        setLocations(res[0].data)
        setReviewCounts(res[1].data)
      })
    }
    return () => {
      document.removeEventListener('onLocationScan', handleLocationScan)
    }
  }, [locationFrom])

  const handleLocationScan = (event) => {
    const locationScan = event.detail
    const locationOption = locations.find((loc) => {
      return loc.location == locationScan
    })
    if (locationFrom == '') {
      setLocationFrom(locationOption)
    } else {
      setLocationTo(locationOption)
    }
  }

  const convertLocationBarcode = (barcode) => {
    const re = /^E2([A-Z])(\d\d)([a-z])(\d\d)/i
    const cg = barcode.match(re) // cg for capture group
    let validLocation
    if (cg) {
      let locationStr = `${cg[1]}-${cg[2]}-${cg[3]}-${cg[4]}`
      validLocation = locations.find(
        (location) => location.location == locationStr)
    }
    return validLocation
  }

  const queryLocationProductsOnHand = () => {
    const params = {
      locationFrom: locationFrom.location,
      locationTo: locationTo.location
    }
    axios.get('/api/get-location-products-on-hand', { params })
      .then((res) => {
        setOnHand(res.data)
      })
  }

  const intakeColumns = [
    {
      Header: 'SKU',
      accessor: 'sku',
    },
    {
      Header: 'Location',
      accessor: 'location'
    },
    {
      Header: 'Brand',
      accessor: 'brand',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'On Hand',
      accessor: 'onHand',
    },
  ]
  const activeColumns = [
    {
      Header: 'Count Set ID',
      accessor: 'countSetId',
      Cell: (row) => (
        <div style={{ textAlign: "center" }}>
          {row.value}
        </div>
      ),
      width: 120
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 60
    },
    {
      Header: 'Location',
      accessor: 'location',
      width: 85
    },
    {
      expander: true,
      Header: 'Products',
      accessor: '_original',
      sortable: true,
      width: 100,
      Expander: ({ isExpanded, row }) => {
        return (
          <div style={{ display: 'inline-flex', paddingTop: 6 }}>
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            ({row._original.totalCounted}/{row._original.totalOnHand})
          </div>
        )
      }
    },
    {
      Header: 'Date Created',
      accessor: 'createdAt',
      Cell: (row) => {
        /** @type {Date} */
        const dateCreated = new Date(row.original.createdAt)
        return dateCreated.toDateString()
      },
      width: 140
    },
    {
      Header: 'Void Reason',
      width: 180,
      Cell: (row) => {
        return (
          <TextareaAutosize
            key={`void-reason:${row.original._id}`}
            style={{ height: 40 }}
            placeholder='void reason'
            onChange={(event) => {
              const reviewCountsClone = [...reviewCounts]
              const reviewLocation = reviewCountsClone.find(
                (loc) => loc.locationId == row.original.locationId
              )
              reviewLocation['voidReason'] = event.target.value
              setReviewCounts(reviewCountsClone)
            }}
            onKeyDown={(event) => event.stopPropagation()}
          />
        )
      }
    },
    {
      Header: 'Void',
      width: 105,
      Cell: (row) => {
        return (
          <Button
            className={classes.voidButton}
            variant='contained'
            disabled={!row.original.voidReason || row.original.voidReason == ''}
            onClick={() => {
              axios.post('/api/update-location-void', {
                countSetId: row.original.countSetId,
                locationId: row.original.locationId,
                reason: row.original.voidReason
              }).then((_res) => {
                console.log('location voided')
                const params = { status: 'review' }
                return axios.get('/api/get-location-counts', { params })
              }).then((res) =>{
                setReviewCounts(res.data)
              })
            }}
          >
            <DeleteForeverIcon/>void
          </Button>
        )
      }
    }
  ]
  const productColumns = [
    {
      Header: 'SKU',
      accessor: 'sku',
      width: 100,
    },
    {
      Header: 'Brand',
      accessor: 'brand',
      width: 160,
    },
    {
      Header: 'Name',
      accessor: 'name',
      width: 240,
    },
    {
      Header: 'On Hand',
      accessor: 'onHand',
      width: 80,
      Cell: (row) => (
        <div style={{ textAlign: "center" }}>
          {row.value}
        </div>
      )
    },
    {
      Header: 'Counted',
      accessor: 'counted',
      width: 80,
      Cell: (row) => (
        <div style={{ textAlign: "center" }}>
          {row.value}
        </div>
      )
    },
    {
      Header: 'Note',
      accessor: 'note',
      width: 160,
      Cell: (row) => {
        return (
          <Typography
            style={{
              fontSize: 13,
              whiteSpace: 'pre-line',
            }}>
            {row.value}
          </Typography>)
      }
    },
    {
      Header: 'Misplaced/Mismatched',
      Cell: (row) => {
        let message = ''
        if (row.original.misplaced) {
          message = 'This product is misplaced in the following location(s) '+
            row.original.mismatchedLocations.map((loc) => `${loc}, `)
        } else if (row.original.mismatched) {
          message = 'Total onHand vs Counted is mismatched for this product '
        }
        return (
          <Typography
            style={{
              fontSize: 13,
              whiteSpace: 'pre-line',
            }}>
            {message}
          </Typography>
        )
      }
    },
  ]


  /** Get index of `loc`
   * @param {Destination} loc */
  const locIndex = (loc) => {
    return locations.findIndex(
      (whLocation) => whLocation.location == loc.location
    )
  }
  let locationsAfter = locationFrom != '' ?
    locations.slice(locIndex(locationFrom)) : locations
  let locationsBefore = locationTo != '' ?
    locations.slice(0, locIndex(locationTo)) : locations

  const notificationModal = (
    <Dialog
      open={openModal}>
      <DialogTitle>
        {modalContent.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText component={'span'}>
          {modalContent.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpenModal(false)
            setModalContent({})
          }}
        >Close</Button>
      </DialogActions>
    </Dialog>
  )

  return (
    <>
      {notificationModal}
      <Typography
        style={{ marginInline: 15, marginTop: 5 }}
        variant='subtitle1'>
        Enter a range of main warehouse locations to get a count of products on hand
      </Typography>
      <Grid container spacing={2} alignItems='center' style={{ padding: 15 }}>
        <Grid item xs={6} sm={3} >
          <LocationAutocomplete
            value={locationFrom}
            onChange={(_event, newValue, reason) => {
              if (reason == 'clear')
                setLocationFrom('')
              else if (newValue)
                setLocationFrom(newValue)
            }}
            options={locationsBefore}
            onKeyDown={(event) => event.stopPropagation()}
            onKeyUp={(event) => {
              if (event.key == 'Enter') {
                let locStr = convertLocationBarcode(event.target.value)
                if (locStr)
                  setLocationFrom(locStr)
              }
            }}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <LocationAutocomplete
            value={locationTo}
            tabIndex={-1}
            onChange={(_event, newValue, reason) => {
              if (reason == 'clear')
                setLocationTo('')
              else if (newValue)
                setLocationTo(newValue)
            }}
            options={locationsAfter}
            onKeyDown={(event) => event.stopPropagation()}
            onKeyUp={(event) => {
              if (event.key == 'Enter') {
                let locStr = convertLocationBarcode(event.target.value)
                if (locStr)
                  setLocationTo(locStr)
              }
            }}
          />
        </Grid>
        <Grid item xs={6} sm={3} lg={2}
          style={{ textAlign: 'center' }}>
          <Button
            fullWidth={true}
            size='large'
            variant='contained'
            onClick={() => {
              queryLocationProductsOnHand()
            }}
          >Query On Hand</Button>
        </Grid>
        <Grid item xs={6} sm={3} lg={2}>
          <Button
            disabled={onHand.length == 0 ||
              locationFrom == '' || locationTo == ''}
            fullWidth={true}
            size='large'
            variant='contained'
            color='secondary'
            style={{ background: '#34b4c9' }}
            onClick={(_event) => {
              axios.post('/api/create-location-count', {
                locationFrom: locationFrom.location,
                locationTo: locationTo.location
              }).then((res) => {
                console.log(res)
                if (res.data.status == 'exists') {

                  setOpenModal(true)
                  setModalContent({
                    title: 'Couldn\'t create count set',
                    message: (
                      <span>
                        Couldn't create count set because it includes location counts which
                        are active or in review. See the following table for more info.
                        <SortableBasicTable
                          columns={[
                            {
                              Header: 'Location',
                              accessor: 'location'
                            },
                            {
                              Header: 'Count Set Id',
                              accessor: 'countSetId'
                            },
                            {
                              Header: 'Status',
                              accessor: 'status'
                            }
                          ]}
                          data={
                            res.data.locations
                          }
                        />
                      </span>
                    )
                  })
                }
                setOnHand([])
              })
            }}
          >Create New Count</Button>
        </Grid>
      </Grid>
      {onHand.length > 0 && (
        <SortableBasicTable
          columns={intakeColumns}
          data={onHand}
          defaultSorted={[
            { id: 'location', desc: false },
            { id: 'brand', desc: false },
            { id: 'onHand', desc: true }
          ]}
        />
      )}
      <ReactTable
        className='-striped'
        data={reviewCounts}
        columns={activeColumns}
        pageSize={reviewCounts.length}
        showPageSizeOptions={false}
        showPagination={false}
        minRows={5}
        expanded={expanded}
        onExpandedChange={(newExpanded) => {
            setExpanded(newExpanded)
        }}
        defaultSorted={[
          { id: 'createdAt', desc: false },
          { id: 'countSetId', desc: false },
          { id: 'location', desc: false },
        ]}
        SubComponent={(row) => {
          return (
            <ReactTable
              className='-striped'
              data={[...row.original.products]}
              getTrProps={(_state, rowInfo, _column) => {
                const rowData = rowInfo.original
                let rowStyle = {}
                if (rowData.misplaced) {
                  rowStyle = { background: 'skyblue' }
                } else if (rowData.mismatched) {
                  rowStyle = { background: 'firebrick', color: 'white' }
                }
                if (rowInfo) {
                  return {
                    style: {
                      ...rowStyle
                    }
                  }
                } else {
                  return {}
                }
              }}
              resolveData={(products) => {
                let resolvedProducts = products.map(product => {
                  let mismatched = mismatchProducts.find(mismatchProduct => {
                    return (
                      mismatchProduct.countSetId == row.original.countSetId &&
                      mismatchProduct.bpId == product.bpId
                    )
                  })
                  let mismatchedLocations = []
                  let misplaced = false
                  if (mismatched) {
                    mismatchedLocations = mismatched.locations
                      .filter(loc => loc.locationId != row.original.locationId)
                      .map(loc => {
                      return loc.location
                      })
                    misplaced = mismatched.totalCounted == mismatched.totalOnHand
                  }
                  return {
                    ...product,
                    mismatched: mismatched ? true : false,
                    misplaced: misplaced,
                    mismatchedLocations: mismatchedLocations
                  }
                })
                return resolvedProducts
              }}
              columns={productColumns}
              filterable
              pageSize={row.original.products.length + 1}
              showPageSizeOptions={false}
              minRows={5}
              showPagination={false}
              defaultSorted={[
                { id: 'brand', desc: false },
                { id: 'onHand', desc: true },
                { id: 'sku', desc: false },
              ]}
            />
          )
        }}
      />
    </>
  )
}
export default CreateReviewLocationCount
