import axios from 'axios'
import React, { useState, useEffect, useRef } from 'react'
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel'
import BrandLocationTable from './BrandLocationTable'
import CreateReviewLocationCount from './CreateReviewLocationCount'
import LocationAutocomplete from './LocationAutocomplete'
import LocationCount from './LocationCount'
import NumberInputMobile from './NumberInputMobile'
import PaperPage from './PaperPage'
import TopBar from './TopBar'
import '../types'


const dialogTheme = createTheme({
  overrides: {
    MuiDialogContentText: {
      root: {
        marginBottom: '0px'
      }
    }
  }
})

const WarehouseLocaiton = () => {
  const TransferStatus = Object.freeze({
    READY: 'READY',
    SKU_ERROR: 'SKU_ERROR',
    TRANSFERING: 'TRANSFERING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR'
  })

  const BarcodeType = Object.freeze({
    SKU: 'SKU',
    UPC: 'UPC',
    LOCATION: 'LOCATION',
    OTHER: 'OTHER'
  })

  const TabIndex = Object.freeze({
    TRANSFER: 0,
    BRAND: 1,
    COUNT: 2,
    CREATE_REVIEW: 3
  })
  // Page State ---------------------------------------------------------------
  const [tab, setTab] = React.useState(0)

  // Location Transfer State / Refs -------------------------------------------
  /** @type {[SkuLocations, React.Dispatch<SkuLocation>]}  */
  const [skuLocations, setSkuLocations] = useState({}) // Scanned SKU locations
  /** @type {[Transfers, React.Dispatch<Transfers>]} */
  const [transfers, setTransfers] = useState({}) // Transfers to send to bp
  /** @type {[Destination[], React.Dispatch<Destination[]>]} */
  const [allLocations, setAllLocations] = useState([]) // List of all locations
  /** @type {[Destination, React.Dispatch<Destination>]} */
  const [destination, setDestination] = useState('') // Transfer destination
  /** @type {[SkuLocation, React.Dispatch<SkuLocation>]} */
  const [errorProduct, setErrorProduct] = useState({})
  const [transferStatus, setTransferStatus] = useState(TransferStatus.READY)
  const [transferError, setTransferError] = useState('') // Error message for failed transfer
  const [currentSkuOrUPC, setCurrentSkuOrUPC] = useState('')     // SKU or UPC in TextField
  const captureText = useRef('') // Capture barcode scan on entire page
  // ---------------------------------------------------------------------------
  // Brand Location State ------------------------------------------------------
  /** @type {[Brand, React.Dispatch<Brand[]>]} */
  const [brands, setBrands] = useState([])
  /** @type {[BrandLocation[], React.Dispatch<BrandLocation[]>]} */
  const [brandLocations, setBrandLocations] = useState([])
  const [currentBrand, setCurrentBrand] = useState('')
  // --------------------------------------------------------------------------
  // Active Location Count State ----------------------------------------------
  const [activeCountSetId, setActiveCountSetId] = useState()
  const [activeExpanded, setActiveExpanded] = useState([])
  // --------------------------------------------------------------------------
  // Review Location Count State ----------------------------------------------
  const [reviewExpanded, setReviewExpanded] = useState([])
  // --------------------------------------------------------------------------
  // Regexes for parsing barcode scan -----------------------------------------
  const reSku = /^(N|U|W)\d+-[0-9]+(-[a-z])*/i // SKU barcode
  const reUpc = /\d{12}/i // 12 digit number
  const reLocation = /^E2([A-Z])(\d\d)([a-z])(\d\d)/i // Loction barcode
  // --------------------------------------------------------------------------


  useEffect(() => {
    if (!allLocations.length) {
      getAllLocations()
    }
    if (!brands.length) {
      getAllBrands()
    }

    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [skuLocations, allLocations, tab])

  const noTransfers = Object.keys(transfers).length == 0
  const noDestination = destination == ''
  const transfering = transferStatus == TransferStatus.TRANSFERING

  const getAllBrands = () => {
    axios.get('/api/brandNames').then((res) => {
      setBrands(res.data)
    })
  }

  const handleChangeBrand = (_event, newValue) => {
    if (newValue) {
      setCurrentBrand(newValue)
      const params = {
        brand: newValue.name
      }
      axios.get('/api/get-locations-for-brand', { params })
        .then((res) => {
          setBrandLocations(res.data)
        })
    }
  }

  const handleScannedBarcode = () => {
    const barcode = captureText.current
    const barcodeType = parseBarcode(barcode)

    if (tab == TabIndex.TRANSFER) {
      if (barcodeType == BarcodeType.LOCATION) {
        const locationStr = convertLocationBarcode(barcode)
        setValidDestination(locationStr)
      }
      else if (barcodeType == BarcodeType.SKU ||
        barcodeType == BarcodeType.UPC) {
        setCurrentSkuOrUPC(barcode)
        fetchAndAddSkuLocation(barcodeType, barcode)
      }
    }
    else if (tab == TabIndex.COUNT) {
      if (barcodeType == BarcodeType.SKU) {
        const skuScanEvent = new CustomEvent('onSkuScan', { detail: barcode })
        document.dispatchEvent(skuScanEvent)
      } else {
        const notSkuScanEvent = new CustomEvent('notSkuScan', { detail: barcode })
        document.dispatchEvent(notSkuScanEvent)
      }
    }
    else if (tab == TabIndex.CREATE_REVIEW) {
      if (barcodeType == BarcodeType.LOCATION) {
        const locationStr = convertLocationBarcode(barcode)
        const locationScanEvent = new CustomEvent(
          'onLocationScan', { detail: locationStr }
        )
        document.dispatchEvent(locationScanEvent)
      }
    }
  }

  /**
   * Capture SKUs and location ids from
   * the window and populate corresponding fields once ENTER key is pressed
   */
    const handleKeyPress = (event) => {
      if (event.key == 'Enter') {
        handleScannedBarcode()
        captureText.current = ''
        return
      }
    // test if input key is alphanumeric
    const isLetter = event.keyCode >= 65 && event.keyCode <= 90
    const isNumber = event.keyCode >= 48 && event.keyCode <= 57
    const isMinus = event.key == '-'
    if (isLetter || isNumber || isMinus) {
      captureText.current = captureText.current + event.key
      console.log(captureText.current)
    }
  }

  const setValidDestination = (locationStr) => {
    const validLocation = allLocations.find(
      (location) => location.location == locationStr)
    setDestination(validLocation)
  }

  /**
   * @param {string} barcode
   * @returns {BarcodeType}
   */
  const parseBarcode = (barcode) => {

    let regexes = [reSku, reUpc, reLocation]
    let barcodeTypes = [BarcodeType.SKU, BarcodeType.UPC, BarcodeType.LOCATION]
    for (let i = 0; i < regexes.length; i++) {
      const match = barcode.match(regexes[i])
      if (match) {
        return barcodeTypes[i]
      }
    }
    return BarcodeType.OTHER
  }
  /**
   * Determine if `barcode` is a location id from the warehouse and if it is
   * reformat `barcode` by removing E2 prefix and adding dashes
   *   e.g. (E2J01A01 -> J-01-A-01)
   * @param {String} barcode - Barcode read from Main Warehouse locations
   */
  const convertLocationBarcode = (barcode) => {
    const match = barcode.match(reLocation)
    if (match)
      return `${match[1]}-${match[2]}-${match[3]}-${match[4]}`
    return null
  }
  /**
   * @param {BarcodeType} barcodeType
   * @param {string} barcode
   */
  const fetchAndAddSkuLocation = (barcodeType, barcode) => {
    let url, params
    if (barcodeType == BarcodeType.SKU) {
      url = '/api/get-sku-locations'
      params = { sku: barcode.toUpperCase() }
    } else if (barcodeType == BarcodeType.UPC) {
      url = '/api/get-upc-locations'
      params = { upc: barcode }
    } else {
      return
    }
    axios.get(url, { params }).then((res) => {
      if (res.data.length) {
        let product = res.data[0]
        if (JSON.stringify(product.locations[0]) == '{}') {
          setErrorProduct(product)
          setTransferStatus(TransferStatus.SKU_ERROR)
          return
        }
        addSkuLocation(product)
      }
    })
  }

  const getAllLocations = () => {

    axios.get('/api/get-all-locations').then((res) => {
      setAllLocations(res.data)
    })
  }

  const findSkuInLocation = (sku, locationId) => {
    return skuLocations[sku].locations.find((whLocation) => {
      return whLocation.locationId == locationId
    })
  }

  const transferLocation = () => {
    /**
     * @typedef Inventory
     * @property {string} location
     * @property {number} onHand
     */
    let transferBody = []       // Create a new entry for each transfer
    /** @type {Object.<string, Array<Inventory>}*/
    let newInventories = {}

    setTransferStatus(TransferStatus.TRANSFERING)

    Object.keys(transfers).forEach((transferKey) => {
      let transfer = transfers[transferKey]
      // Add toLocationId to brightpearl tranfer body
      transfer['toLocationId'] = destination.id
      transferBody.push(transfer)

      // Calculate new product inventories for mongodb
      const sku = transferKey.split('.')[0]
      const qty = parseInt(transfer.quantity)

      let locationFrom = findSkuInLocation(sku, transfer.fromLocationId)
      const newQtyFrom = parseInt(locationFrom.onHand) - qty

      let locationTo = findSkuInLocation(sku, destination.id)
      const newQtyTo = locationTo ? parseInt(locationTo.onHand) + qty : qty

      let bpId = transfer.productId
      if (!newInventories[bpId])
        newInventories[bpId] = []

      newInventories[bpId].push({
        location: transfer.fromLocationId,
        onHand: newQtyFrom
      })
      // Check if Location to already exists in new Inventories
      let existingTo = newInventories[bpId].find((inventory) => {
        return inventory.location === destination.id
      })

      if (existingTo) {
        // If so add to existing qty.
        existingTo.onHand = parseInt(existingTo.onHand) + newQtyTo
      } else {
        // otherwise create a new Inventory entry
        newInventories[bpId].push({
          location: destination.id,
          onHand: newQtyTo
        })
      }
    })
    // combine duplicates
    axios.post('/api/transfer-location', {
      bpTransferBody: transferBody,
      newInventories: newInventories
    }).then((resp) => {
      console.log(resp)
      setTransferStatus(TransferStatus.SUCCESS)
    }).catch((error) => {
      const errorData = error.response.data.error
      setTransferError(JSON.stringify(errorData))
      setTransferStatus(TransferStatus.ERROR)
    })
  }
  /**
   * If scanned SKU is in default location, than add maximum on hand to
   * transfer from the default location to the
   * @param {SkuLocation} skuLocation
   */
  const setInitialSkuTransfer = (skuLocation) => {
    let transfersClone = { ...transfers }

    skuLocation.locations.forEach((whLocation) => {
      const transferKey = skuLocation.sku + '.' + whLocation.locationId
      const locationStr = whLocation.location

      const inDefaultLocation = locationStr == '0-0-0-0' || locationStr == 'E1'
      if (inDefaultLocation && whLocation.onHand > 0) {
        transfersClone[transferKey] = {
          'productId': skuLocation.bpId,
          'quantity': whLocation.onHand,
          'fromLocationId': whLocation.locationId
        }
      }
    })
    setTransfers(transfersClone)
  }

  /**
   * Update post body for this sku/location transfer with entered `quantity`.
   * @param {Number} quantity - the new quantity of this sku to transfer
   * @param {Number} bpId - the bp product id being transfered
   * @param {String} sku - the sku being transfered
   * @param {LocationOnHand} locationData - data about sku at this location
   */
  const updateTransfer = (quantity, bpId, sku, locationData) => {
    let transfersClone = { ...transfers }
    const transferKey = sku + '.' + locationData.locationId
    if (quantity > locationData.onHand) {
      return
    }
    if (quantity <= 0) {
      delete transfersClone[transferKey]
      setTransfers(transfersClone)
      return
    }
    // clone locationTransfers
    // Create internal transfer post body entry for this transfer
    transfersClone[transferKey] = {
      'productId': bpId,
      'quantity': quantity,
      'fromLocationId': locationData.locationId
    }
    // update state of all transfers
    setTransfers(transfersClone)
  }

  /**
   * @param {SkuLocation} skuLocation
   */
  const addSkuLocation = (skuLocation) => {
    const sku = skuLocation.sku
    setInitialSkuTransfer(skuLocation)
    setSkuLocations({
      ...skuLocations,
      [sku]: skuLocation
    })
  }

  /**
   * Remove this sku from all transfers and delete sku card
   * @param {String} sku - product SKU to delete
   */
  const handleDeleteSku = (_event, sku) => {
    let transferClone = { ...transfers }
    Object.keys(transferClone).forEach((transferKey) => {
      if (transferKey.includes(sku)) {
        delete transferClone[transferKey]
      }
    })
    setTransfers(transferClone)
    let newSkuLocations = { ...skuLocations }
    delete newSkuLocations[sku]
    setSkuLocations(newSkuLocations)
  }

  let skuLocationCards = Object.keys(skuLocations).map((sku, index) => {
    const noImage = "https://www.perfectcircuit.com/media/catalog/product" +
      "/placeholder/default/m2_placeholder.jpg"
    const images = skuLocations[sku].images
    const skuImage = images ? images[0] : noImage
    return (
      <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
        <Card variant='outlined'>
          <CardContent>
            <Box style={{ position: 'relative', height: 0, left: '85%' }}>
              <Button
                onClick={(event) => handleDeleteSku(event, sku)}
                size='large' >
                <CancelIcon fontSize='medium' style={{ fill: 'black' }} />
              </Button>
            </Box>
            <Grid container spacing={1}>
              <Grid xs={6} container item>
                <Grid item xs={12}>
                  <Typography variant='body2' color='textSecondary'>
                    Name
                  </Typography>
                  <Typography variant='body2' style={{ fontWeight: 600 }}>
                    {skuLocations[sku].bpName}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2' color='textSecondary'>
                    SKU
                  </Typography>
                  <Typography variant='body2' style={{ fontWeight: 600 }}>
                    {sku}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <CardMedia
                  component='img'
                  height='100%'
                  image={skuImage}
                  title={skuLocations[sku].bpName}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid container item xs={12} alignItems="flex-end"
                style={{
                  borderBottom: '1px solid #e5e5e5'
                }}>
                <Grid item xs={3}>
                  <Typography variant='body2' color='textSecondary'>
                    Location
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant='body2' color='textSecondary'>
                    On Hand
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    align='center' variant='body2' color='textSecondary'>
                    Transfer
                  </Typography>
                </Grid>
              </Grid>
              {skuLocations[sku].locations.map((skuLocation, index) => {
                const bpId = skuLocations[sku].bpId
                // transfer key represents a unique location transfer
                const transferKey = sku + '.' + skuLocation.locationId
                // extract transfer quantity for this transfer from state
                // or set to 0 if no transfer exists yet
                const transfer = transfers[transferKey]
                const transferQty = transfer ? transfer.quantity : 0
                if (skuLocation.onHand == 0)
                  return (<></>)
                return (
                  <Grid container item xs={12} alignItems="center" key={index}>
                    <Grid item xs={3}>
                      <Typography variant='body2'>
                        {skuLocation.location}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography align='center'>{skuLocation.onHand}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <NumberInputMobile
                        maxInputWidth={50}
                        value={transferQty}
                        onChange={(event) => updateTransfer(
                          event.target.value, bpId, sku, skuLocation
                        )}
                        onKeyDown={(event) => event.stopPropagation()}
                      />
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    )
  })

  let dialogTitle = ""
  let dialogMessage = ""
  if (transferStatus == TransferStatus.SUCCESS) {
    /**
     * @typedef Transferred
     * @property {string} sku
     * @property {number} [quantity]
     */
    let /** @type {Transferred[]} */ transferred = []
    let /** @type {Transferred[]} */ notTransferred = []

    Object.keys(skuLocations).forEach((sku) => {
      let totalQtyTransferred = 0
      skuLocations[sku].locations.forEach((whLocation) => {
        const transferKey = `${sku}.${whLocation.locationId}`
        const transfer = transfers[transferKey]
        if (transfer) {
          totalQtyTransferred += parseInt(transfer.quantity)
        }
      })
      if (totalQtyTransferred > 0) {
        transferred.push({
          sku: sku,
          quantity: totalQtyTransferred
        })
      } else {
        notTransferred.push({
          sku: sku
        })
      }
    })
    dialogTitle = "Transfer Success"
    dialogMessage = (
      <>
        <DialogContent>
          <DialogContentText component='div'>Transfered:</DialogContentText>
          <List>
            {transferred.map((transfer, index) => {
              return (
                <ListItem key={index}>
                  <ListItemText
                    primaryTypographyProps={{ style: { fontWeight: 500 } }}>
                    ({transfer.quantity}) {transfer.sku}
                  </ListItemText>
                </ListItem>
              )
            })}
          </List>
          <DialogContentText>to {destination.location}</DialogContentText>
        </DialogContent>
        {(notTransferred.length > 0) &&
          <DialogContent>
            <DialogContentText component='div'>
              Not Transferred:
            </DialogContentText>
            <List>
              {notTransferred.map((nonTransfer, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemText
                      primaryTypographyProps={{
                        style: { color: 'red', fontWeight: '500' }
                      }}>
                      {nonTransfer.sku}
                    </ListItemText>
                  </ListItem>
                )
              })}
            </List>
          </DialogContent>
        }
      </>
    )
  } else if (transferStatus == TransferStatus.ERROR) {
    dialogTitle = "Transfer Error"
    dialogMessage = (
      <DialogContent>
        <DialogContentText>
          <Typography style={{ whiteSpace: 'pre-line' }}>
            {transferError}
          </Typography>
        </DialogContentText>
      </DialogContent>
    )
  } else if (transferStatus == TransferStatus.SKU_ERROR) {
    dialogTitle = `${errorProduct.sku} is out of stock`
    dialogMessage = (
      <DialogContent>
        <DialogContentText>
          <Typography component="span">
            {errorProduct.bpName} is out of stock in Main Warehouse
          </Typography>
        </DialogContentText>
      </DialogContent>
    )
  }

  let responseModal = (
    <ThemeProvider theme={dialogTheme}>
      <Dialog
        open={
          transferStatus == TransferStatus.SUCCESS ||
          transferStatus == TransferStatus.ERROR ||
          transferStatus == TransferStatus.SKU_ERROR
        }>
        <DialogTitle>
          {dialogTitle}
        </DialogTitle>
        {dialogMessage}
        <DialogActions>
          <Button
            fullWidth
            onClick={() => {
              if (transferStatus == TransferStatus.SKU_ERROR) {
                setCurrentSkuOrUPC('')
                setErrorProduct({})
                setTransferStatus(TransferStatus.READY)
              } else {
                setTransfers({})
                setSkuLocations({})
                setCurrentSkuOrUPC('')
                setDestination('')
                setTransferStatus(TransferStatus.READY)
              }
            }}
          >OK</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )

  return (
    <div>
      <PaperPage>
        <TopBar pageName='Warehouse Location' />
        <AppBar position='static' style={{ boxShadow: 'none' }}>
          <Tabs value={tab} onChange={(_event, value) => setTab(value)}>
            <Tab label="Transfer" />
            <Tab label="Find Brand" />
            <Tab label="Active Counts" />
            <Tab label="Create/Review Counts" />
          </Tabs>
        </AppBar>
        {tab == TabIndex.TRANSFER && (
          <span>
            <Typography
              style={{ marginInline: 15, marginTop: 5 }}
              variant='subtitle1'>
              Scan barcode or type in SKU and press ENTER to add to transfer
            </Typography>
            <Grid container alignItems='center' spacing={2} style={{ padding: 15 }}>
              <Grid item xs={12} sm={4} lg={3}>
                <TextField
                  fullWidth={true}
                  variant='outlined'
                  value={currentSkuOrUPC}
                  onChange={(event) => setCurrentSkuOrUPC(event.target.value)}
                  onClick={() => setCurrentSkuOrUPC('')}
                  onKeyDown={(event) => event.stopPropagation()}
                  onKeyUp={(event) => {
                    if (event.key == 'Enter') {
                      const barcode = event.target.value
                      const barcodeType = parseBarcode(barcode)
                      fetchAndAddSkuLocation(barcodeType, barcode)
                    }
                  }}
                  label='SKU or UPC' />
              </Grid>
              <Grid item xs={12} sm={4} lg={3}>
                <LocationAutocomplete
                  tabIndex={-1}
                  value={destination}
                  onChange={(_event, newValue, reason) => {
                    if (reason == 'clear')
                      setDestination('')
                    else if (newValue) {
                      setDestination(newValue)
                    }
                  }}
                  options={allLocations}
                  onKeyDown={(event) => event.stopPropagation()}
                  onKeyUp={(event) => {
                    if (event.key == 'Enter') {
                      const barcode = event.target.value
                      const barcodeType = parseBarcode(barcode)
                      if (barcodeType == BarcodeType.LOCATION) {
                        const locationStr = convertLocationBarcode(barcode)
                        setValidDestination(locationStr)
                      }
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2} xl={1}>
                <Button
                  fullWidth={true}
                  size='large'
                  variant='contained'
                  disabled={noTransfers || noDestination || transfering}
                  onClick={() => {
                    transferLocation()
                  }}
                >Transfer
                </Button>
              </Grid>
            </Grid>
            {transfering && <LinearProgress />}
          </span>
        )}
        {tab == TabIndex.BRAND && (
          <BrandLocationTable
            currentBrand={currentBrand}
            brands={brands}
            brandLocations={brandLocations}
            onChangeBrand={handleChangeBrand}
          />
        )}
        {tab == TabIndex.COUNT && (
          <LocationCount
            locations={allLocations}
            activeCountSetId={activeCountSetId}
            setActiveCountSetId={setActiveCountSetId}
            expanded={activeExpanded}
            setExpanded={setActiveExpanded}
          />
        )}
        {tab == TabIndex.CREATE_REVIEW && (
          <CreateReviewLocationCount
            expanded={reviewExpanded}
            setExpanded={setReviewExpanded}
          />
        )}
      </PaperPage>
      {tab == TabIndex.TRANSFER && (
        <span>
          <Box
            display='flex' alignItems='center' justifyContent='center'
            style={{ margin: 20 }}>
            <Grid container spacing={4} style={{ maxWidth: 1440 }}>
              {skuLocationCards}
            </Grid>
          </Box>
          {responseModal}
        </span>
      )}
    </div>
  )
}

export default WarehouseLocaiton
